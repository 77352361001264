<template src="./EditRecommededCourse.html">
</template>

<script>

import coursesService from "@/services/Courses";
import UploadImageModal from "@/components/UploadImageModal/UploadImageModal";

export default {
  name: "EditRecommendedCourse",
  components:{
    UploadImageModal
  },
  data() {
    return {
      file: null,
      courseName: '',
      universityName: '',
      courseLink: '',
      level: '',
      imageDescription: 'fotoDescripción',
      type: '',
      course: "type",
      AllCoursesByInstitution:[],
      selectedCourse: null,
      isActive: false,
      infoFile: null,
      imageLink: null,
      courseData: {},
      data: {}
    }
  },
  methods:{
    async getRecommendedCourseById(){
      try {
        this.data = (await coursesService.getRecommendedCourseById(this.$route.params.courseId))[0];
      }catch (e) {
        console.log('e',e);
      }
    },
    async createRecommendedCourse(){
      try {
        if( this.infoFile ) {
          this.imageLink = await coursesService.uploadFile('imagesRecommendedCourse', 'imagesRecommendedCourse', this.infoFile.name, this.infoFile.file);
        }
        const info = {
          name: this.data.name,
          level: this.data.level,
          url: this.data.url,
          image: this.infoFile? this.imageLink: this.data.image,
          imageDescription: this.infoFile? this.infoFile.description : this.data.imageDescription,
          type: this.data.type,
          institution: this.data.institution,
          course: this.data.course
        }
        await coursesService.updateRecommendedCourse(this.data.id, info);
        this.$router.push({ name: "RecommendedCourses"});
        this.$buefy.toast.open({
          duration: 5000,
          message: "Curso recomendado creado correctamente",
          position: "is-bottom",
          type: "is-success"
        });
      }catch (e) {
        console.log('e',e);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Error creando curso!",
          type: "is-error",
        });
      }
    },
  },
  beforeMount() {
    this.getRecommendedCourseById();
  }
}
</script>

<style scoped lang="scss" src="./EditRecommendedCourses.scss">
</style>
